import React, { useState, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import { FiFilter } from 'react-icons/fi';
import './Filters.scss';
import useSWR from 'swr';
import { getCompanyList } from "../../services/Company";

//All the Filter component does is display the filters according to the metaData array and then manipulate the filterArr state variable. No filtering actually happens in this component. 
const Filters = (filterData: any,) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { data }: any = useSWR('getCompanyList', getCompanyList);

    useEffect(() => {
        //Company list for filters is annoying
        if (data) {
            if (filterData.filterKeys.keyValues[1].label === 'Company') {
                let companyNames: Array<string> = [];
                data.map((company: any) => {
                    companyNames.push(company.name)
                })

                filterData.filterKeys.keyValues[1].values = companyNames;
            }
        }
    }, [data]);

    const handleFilterChange = (key: string, value: string) => {

        let tempArr = filterData.filterArr.filter((ele: any) => {
            return ele.key != key; // removes old filter object
        })
        //Adds new filter object unless value was changed to all.
        value != 'All' ? filterData.setFilterArr([...tempArr, { key: key, value: value }]) : filterData.setFilterArr([...tempArr])
    }

    return (
        <>
            <button className='' onClick={handleShow}>
                <FiFilter /> Filters {'(' + filterData.filterArr.length + ')'}
            </button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <Accordion defaultActiveKey={filterData.keys}>
                        {filterData.filterKeys.keyValues.map((filter: any, index: number) => {
                            return (
                                <div className='filterBox' key={index}>
                                    <Accordion.Item eventKey={filter.key}>
                                        <Accordion.Header>{filter.label}: <span className='ms-2'>
                                            {filterData.filterArr.filter((ele: any) => { return ele.key.toLowerCase() === filter.key }).length > 0
                                                ? filterData.filterArr.filter((ele: any) => { return ele.key.toLowerCase() === filter.key })[0].value
                                                : "Any"}
                                        </span></Accordion.Header>
                                        <Accordion.Body>
                                            {filter.values.map((value: string, index: number) => {
                                                return (
                                                    <div className="d-block mt-2" key={index}>
                                                        <input type="radio" id={value} name={filter.key} value={value} checked={(filterData.filterArr.filter((ele: any) => { return ele.value === value })).length > 0} onChange={(e) => { handleFilterChange(filter.key, value) }} />
                                                        <label className="ms-2" htmlFor="newest">{value}</label>
                                                    </div>)
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                            );
                        })}

                    </Accordion>
                </Modal.Body>
                {filterData.filterArr.length > 0 &&
                    <button className='removeFilters ms-2 me-2' onClick={() => { filterData.setFilterArr([]) }}>remove all filters</button>}
                    <button className='ms-2 me-2 mb-2' onClick={() => { handleClose(); }}>
                        Filter
                    </button>
            </Modal>
        </>
    );
}

export default Filters;
