import React, { useState } from "react";
import "./Admin.scss";
import Card from "../Shared/Card";
import {
  FiBox,
  FiHelpCircle,
  FiMail,
  FiFolder,
  FiUsers,
  FiBriefcase,
} from "react-icons/fi";

const Admin = () => {
  return (
    <div className="container mt-5">
      <div className="max-width">
        <h2 className="white">Content</h2>
      </div>

      <div className="pt-2 pb-2">
        <div className="row">
          <Card icon={<FiMail />} title={"Email Templates"} newRoute="./emails/edit" editRoute="./emails" />
          <Card icon={<FiHelpCircle />} title={"FAQs"} newRoute="./faqs/edit" editRoute="./faqs" />
          <Card icon={<FiBox />} title={"Content Blocks"} newRoute="./content-blocks/edit" editRoute="./content-blocks" />
        </div>
      </div>
      <div className="max-width mt-5">
        <h2 className="white">Participants</h2>
      </div>
      <div className="pt-2 pb-2">
        <div className="row">
          <Card icon={<FiBriefcase />} title={"Companies"} newRoute="./companies/edit" editRoute="./companies" />
          <Card icon={<FiUsers />} title={"Registrants"} newRoute="./registrants/edit" editRoute="./registrants/?page=1" />
        </div>
      </div>
    </div>
  );
};

export default Admin;