import Table from 'react-bootstrap/Table';
import { useState } from 'react';
import '../Shared/AdminTable.scss';
import {  tableColumns } from "./metaData";
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import Pagination from "react-bootstrap/Pagination";

const RegistrantsTable = (props: any) => {
    
    const [searchInput, setSearchInput] = useState("");

    const numToDisplay = 20; // number of elements we want to show per page.
    let startIndex = (props.page - 1) * (numToDisplay) + 1;
    let endIndex = (startIndex + numToDisplay - 1 > props.count) ? props.count : startIndex + numToDisplay - 1;

    const tableData = {
        columns: tableColumns,
        rows: props.data.rows,
    };

    const finalPage = Math.ceil(props.count / numToDisplay);
    const showNextPage = () => {
        if (props.page * numToDisplay < props.count) {
            props.setPage(parseInt(props.page) + 1);
        }
    };

    const showPrevPage = () => {
        props.setPage(String(Math.max(props.page - 1, 1)));
    };

    const handleIndividualSelect = (e: any, obj: any) => {
        e.target.checked ? props.setSelectedArray((selectedArray: any) => [...selectedArray, obj]) : props.setSelectedArray((selectedArray: any) => selectedArray.filter((item: any) => item.id !== obj.id));
    }
    const handleSelectAll = (e: any) => {
        e.target.checked ? props.setSelectedArray([...tableData.rows.filter(searchFilterKey).filter(filterKey)]) : props.setSelectedArray([]);
    }
    const searchFilterKey = (row: any) => {
        return row.first_name.toLowerCase().includes(searchInput)
            || row.last_name.toLowerCase().includes(searchInput)
            || row.name.toLowerCase().includes(searchInput)
            || row.status.toLowerCase().includes(searchInput)
    }

    const filterKey = (row: any) => {
        let resp = true;
        if (props.filterArr.length > 0) {
            props.filterArr.forEach((filter: any) => {
                if (resp) {
                    if (filter.key === 'registered_date') {
                        let filterDate = new Date().setHours(0, 0, 0, 0);
                        console.log(filter.value)

                        switch (filter.value.toLowerCase()) {
                            case 'today':
                                resp = new Date(row[filter.key]).setHours(0, 0, 0, 0) === filterDate;
                                break;
                            case 'past 7 days':
                                filterDate = new Date(filterDate).setDate(new Date(filterDate).getDate() - 7)
                                resp = new Date(row[filter.key]).setHours(0, 0, 0, 0) >= filterDate;
                                break;
                            case 'this month':
                                filterDate = new Date(filterDate).getMonth()
                                resp = new Date(row[filter.key]).getMonth() === filterDate;
                                break;
                            case 'this year':
                                filterDate = new Date(filterDate).getFullYear()
                                resp = new Date(row[filter.key]).getFullYear() === filterDate;
                                break;
                        }

                    } else {
                        resp = row[filter.key].toLowerCase() === filter.value.toLowerCase()
                    }

                }
            });
        }
        return resp;

    }

    return (
        <>
            <input type="text" className='searchbar' placeholder="Search.." onChange={(e) => setSearchInput(e.target.value.toLowerCase())} />
            <Table striped className='adminTable'>
                <thead>
                    <tr>
                        {tableData?.columns?.map((col: any, index: number) => {
                            return (
                                <th key={index}>{col?.label} {index === 0 && <input className="" type="checkbox" onChange={(e) => { handleSelectAll(e) }} />}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tableData.rows && tableData.rows.filter(searchFilterKey).filter(filterKey).map((row: any, index: number) => {
                        //pagination

                        return (
                            <tr key={row?.id}>
                                <td>
                                    <div className="d-flex mt-1 selectIndividual">
                                        <input id={row?.id} className='m-auto' type="checkbox" checked={props.selectedArray.filter((e: any) => e.id === row.id).length > 0} onChange={(e) => { handleIndividualSelect(e, row) }} />
                                    </div>
                                </td>
                                <td>{row?.first_name + ' ' + row?.last_name}</td>
                                <td>{row?.name}</td>
                                { row.registered_date ?
                                    <td>{row?.registered_date?.split("T")[0]}</td>
                                    :
                                    <td></td>
                                }
                                <td>
                                    <select className="w-75" value={row?.status} onChange={(e) => props.handleStatusEvent(e, row)}>
                                        <option value="Pending">Pending</option>
                                        <option value='Active'>Active</option>
                                        <option value='Suspended'>Suspended</option>
                                        <option value='Unverified'>Unverified</option>
                                        <option value='Refused'>Refused</option>
                                        <option value='Reminder'>Reminder</option>
                                        <option value='Quarterly'>Quarterly</option>
                                        <option value='Error'>Error</option>
                                    </select>
                                </td>
                                <td className='actionButtons'>
                                    <div className="d-flex actionButtons">
                                        <button onClick={() => { props.handleEditClick(row) }}><FiEdit2 /></button>
                                        <button onClick={() => { props.handleIndividualDelete(row) }}><FiTrash2 /></button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            
            { tableData.rows &&
                <>
                    { props.filterArr.length === 0 ?
                        <span className='showingLabel'>Showing {startIndex}-{endIndex} of {props.count} registrants</span>
                    :
                        <span className='showingLabel'>Showing 1-{tableData.rows.length} of {tableData.rows.length} registrants</span>
                    }
                    { props.filterArr.length === 0 &&
                        <Pagination className="justify-content-end">
                            <Pagination.First onClick={() => { props.setPage('1') }} />
                            <Pagination.Prev onClick={showPrevPage} />
                            { finalPage < 11 ?
                                [...Array(finalPage)].map((x, index: number) =>
                                    <Pagination.Item active={String(index + 1) === props.page} onClick={() => showNextPage() } key={index}>{index + 1}</Pagination.Item>
                                ) 
                                :
                                <>
                                    <Pagination.Item active={'1' === props.page} onClick={() => { props.setPage(String(1)) }}>1</Pagination.Item>
                                    <Pagination.Item active={'2' === props.page} onClick={() => { props.setPage(String(2)) }}>2</Pagination.Item>
                                    <Pagination.Item active={'3' === props.page} onClick={() => { props.setPage(String(3)) }}>3</Pagination.Item>
                                
                                { props.page === '4'  && 
                                    <>
                                        <Pagination.Item active={'4' === props.page} onClick={() => { props.setPage(String(2)) }}>4</Pagination.Item>
                                    </> 
                                }
                                <Pagination.Ellipsis />
                                    { (parseInt(props.page) !== finalPage) && (parseInt(props.page) !== finalPage - 1) && (parseInt(props.page) > 4) && 
                                        <>
                                            
                                            <Pagination.Item active={true} onClick={() => { props.setPage(String(props.page)) }}>{props.page}</Pagination.Item>
                                            <Pagination.Ellipsis />
                                        </>
                                    }
                                    {
                                        (parseInt(props.page) === finalPage - 1) &&
                                            <>
                                                <Pagination.Item active={true} onClick={() => { props.setPage(String(finalPage - 1)) }}>{finalPage - 1}</Pagination.Item>
                                            </>
                                    }
                                    <Pagination.Item active={finalPage === parseInt(props.page)} onClick={() => { props.setPage(String(finalPage)) }}>{finalPage}</Pagination.Item>
                                </>  
                            }

                            <Pagination.Next onClick={showNextPage} />
                            <Pagination.Last onClick={() => props.setPage(String(finalPage))} />
                            
                            </Pagination>
                        
                        }
                </>

            }
        </>
    );
}

export default RegistrantsTable;