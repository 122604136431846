import { useState, useEffect } from "react";
import useSWR from "swr";
import {
  getRegistrants,
  getRegistrantCount,
  getFilteredRegistrants,
  deleteMultipleRegistrants,
  updateRegistrant,
  updateMultipleStatus,
} from "../../services/registrants";
import { useNavigate } from "react-router-dom";
import { tableColumns, filterKeys } from "./metaData";
import Filters from "./Filters";
import "./Registrants.scss";
import RegistrantsTable from "./RegistrantsTable";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import EmailNotifications from "./EmailNotifications";
import LoadByDate from "./LoadByDate";
import ExportExcel from "../Shared/ExportExcel";
import { initialTableData } from "../../interface/registrants";
import { useQueryState } from "../../hooks/useQueryState"
import { getRegistrantsByDate } from "../../services/registrants";

const Registrants = () => {

  const navigate = useNavigate();

  const [page, setPage] = useQueryState("page") || "1";

  const {data: registrantsData, mutate} = useSWR(page, getRegistrants);
  const {data: registrantCount} = useSWR("getRegistrantCount", getRegistrantCount);

  const initialTableData:initialTableData = {
    columns: tableColumns,
    rows: registrantsData,
  };

  const [count, setCount] = useState<number>(0);
  const [selectedArray, setSelectedArray] = useState<any[]>([]);
  const [filterArr, setFilterArr] = useState<any[]>([]);
  let [filterValuesObj, setFilterValuesObj] = useState<object>({});
  const [tableData, setTableData] = useState<initialTableData>(initialTableData);

  const [datesObj, setDatesObj] = useState<object>({});

  const { data: registrantsByDate } = useSWR('to' in datesObj ? { datesObj, page } : null, ({ datesObj, page }) => getRegistrantsByDate(datesObj, page));

  const { data: filteredRegistrants } = useSWR(filterArr.length > 0 ? { filterValuesObj, page } : null, ({ filterValuesObj, page }) => getFilteredRegistrants(filterValuesObj, page));

  useEffect(() => {
    if (registrantsData && !registrantsByDate) {
      setTableData({
        columns: tableColumns,
        rows: registrantsData,
      });
    }
  }, [registrantsData]);

  useEffect(() => {
    if (registrantCount) {
      setCount(registrantCount);
    }
  }, [registrantCount]);

  useEffect(() => {
    if (filteredRegistrants) {
      setTableData({
        columns: tableColumns,
        rows: filteredRegistrants,
      });
      setPage("1");
    }
  }, [filteredRegistrants]);

  useEffect(() => {
    if (filterArr.length > 0) {
      setFilterValuesObj(Object.fromEntries(filterArr.map((filter) => {
        return [filter.key, filter.value];
      })));
    }
  }, [filterArr]);

  const handleStatusEvent = async (e: any, obj: any) => {
    if (selectedArray.length > 0) {
      const text =
        "Do you want to change the status of " +
        selectedArray.length +
        " items to " +
        e.target.value +
        " ?";
      if (window.confirm(text)) {
        await updateMultipleStatus(selectedArray, e.target.value, mutate);
      }
    } else {
      obj.status = e.target.value;
      await updateRegistrant(obj);
      mutate();
    }
    return;
  };

  const handleSelectedDelete = () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete " + selectedArray.length + " items?"
        )
      ) {
        deleteMultipleRegistrants(selectedArray, mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleIndividualDelete = (obj: any) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        //reusing the delete function, just submitting the single object as an array
        deleteMultipleRegistrants([obj], mutate);
      } 
    } catch (e) {
      alert(e);
    }
  };

  const handleNewClick = () => {
    return navigate("/admin/registrants/edit", { state: {} });
  };
  const handleEditClick = (obj: any) => {
    return navigate("/admin/registrant s/edit", { state: obj });
  };

  return (
    <div className="max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb label={"Registrants"} path={"admin"} />
      <div className="d-flex align-items-baseline">
        <h1 className="me-5">Registrants</h1>

                <div className="d-flex ms-auto">
                    <button className="secondary me-2" onClick={handleNewClick}>New</button>
                    <button className="me-5 deleteButton" onClick={handleSelectedDelete}>Delete</button>
                    <LoadByDate 
                      mutate={mutate} 
                      count={count}
                      setCount={setCount}
                      datesObj={datesObj}
                      setDatesObj={setDatesObj}
                      registrantsByDate={registrantsByDate}
                      setTableData={setTableData}
                      tableColumns={tableColumns}
                      page={page}
                      setPage={setPage}
                    />
                    <EmailNotifications selectedArray={selectedArray} />
                    {selectedArray.length > 0 ?
                        <ExportExcel excelData={selectedArray} filename={'CEP-Registrants'}/>:
                        <button className=" reportButton secondary disabled me-2" disabled >Create a Report</button>}
                    <Filters filterArr={filterArr} setFilterArr={setFilterArr} filterKeys={filterKeys} />
                </div>

            </div>
            <div className="table-wrapper">
                { tableData.rows ? <RegistrantsTable
                    data={tableData}
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    handleEditClick={handleEditClick}
                    handleIndividualDelete={handleIndividualDelete}
                    handleStatusEvent={handleStatusEvent}
                    filterArr={filterArr}
                    setFilterArr={setFilterArr}
                    count = {count}
                    setCount = {setCount}
                    page = {page}
                    setPage = {setPage}
                    getRegistrants = {getRegistrants}
                />
              : <div>Loading...</div>}
            </div>
        </div>
    );
};

export default Registrants;
