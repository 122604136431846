import React, { useState, useEffect } from "react";
import "./Registrants.scss";
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';
import { VscCalendar } from 'react-icons/vsc'
import { getRegistrantsByDateCount } from "../../services/registrants";
import useSWR from "swr";



const LoadByDate = (props: any) => {
    const [toDate, setToDate] = useState("");
    const [fromDate, setfromDate] = useState("");

    const { data: registrantsByDateCount } = useSWR('to' in props.datesObj ? { datesObj: props.datesObj } : null, ({ datesObj }) => getRegistrantsByDateCount(datesObj));


    useEffect(() => {

        if('to' in props.datesObj){
            if (props.registrantsByDate) {
                props.setTableData({
                    columns: props.tableColumns,
                    rows: props.registrantsByDate,
                });
            }
            if (registrantsByDateCount) {
                props.setCount(registrantsByDateCount);
            }
        }
    }, [props.datesObj, props.registrantsByDate, registrantsByDateCount]);

    const handleSubmit = async() => {
        props.setPage(1);
        if(toDate && fromDate){
            props.setDatesObj({
                to: toDate,
                from: fromDate
            })
        } else {
            alert("Please enter both dates");
        }
    };

    return (
        <ButtonToolbar className='emailNotification'>
            <DropdownButton title={<VscCalendar />} id={"dropdown-no-caret"} >
                <div className="m-3">
                <div className="d-block">
                        <label>From:</label>
                        <br />
                        <input type="date" className="w-100" value={fromDate} onChange={(e: any) => { setfromDate(e.target.value) }} />
                    </div>
                    <div className="d-block mt-3">
                        <label>To:</label>
                        <br />
                        <input type="date" className="w-100" value={toDate} onChange={(e: any) => { setToDate(e.target.value) }} />
                    </div>
                </div>
                <button className=' sendButton mt-3 ms-3 me-3' onClick={() => { handleSubmit() }}>Load Data</button>
                <button className="removeFilters w-100 ms-3" onClick={()=>{props.setDatesObj({})}}>Reset Data</button>
            </DropdownButton>
        </ButtonToolbar>
    )
}

export default LoadByDate;