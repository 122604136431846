import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom";

import qs from "qs";

interface QueryState {
  [key: string]: any;
}

interface SetQuery {
  (value: any): void;
}
/**
 * Updates the URL query string with the new state value
 * @param query 
 * @returns 
 */
export const useQueryState = (query: string): [any, SetQuery] => {
  const location = useLocation();
  const navigate = useNavigate();

  const setQuery: SetQuery = useCallback(
    (value) => {
      const existingQueries: QueryState = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      const queryString = qs.stringify(
        { ...existingQueries, [query]: value },
        { skipNulls: true }
      );

      navigate(`${location.pathname}?${queryString}`);
    },
    [navigate, location, query]
  );

  return [
    qs.parse(location.search, { ignoreQueryPrefix: true })[query],
    setQuery,
  ];
};